<template>
   <div class="col-md-8" style="margin-right:auto; margin-left:auto"> 
     <vue-html2pdf
        :show-layout="false"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        filename="Certificate"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="letter"
        pdf-orientation="landscape"
        pdf-content-width="1040px"
        pdf-content-height="566px"
        ref="html2Pdf"
    >
        <section slot="pdf-content">
            <html>
                <head>
                    <meta charset="UTF-8">
                        <link rel="preconnect" href="https://fonts.gstatic.com">
                        <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400;1,700&display=swap" rel="stylesheet">
                        <title> {{ $t('certificate.certificate') }}</title>
                </head>
                <body>
                    <div :style=sStyle>
                        <div style="width:900px; height:790px; padding:80px 20px 20px 20px;   margin-left:auto; margin-right:auto; text-align:center; ">
                            <span style="font-size:35px; font-weight:300;">{{ $t('certificate.certificatecompletion') }}</span><br><br><br>
                            <span style="font-size:18px">{{ $t('certificate.documentcertif') }}</span><br><br>
                            <span style="font-size:24px"><b>{{ certificateData.sStudentName }}</b></span><br/><br/>
                            <span style="font-size:18px">{{ $t('certificate.completedcourse') }}</span> <br/><br/>
                            <span style="font-size:30px">{{ certificateData.sExamName }}</span><br/><br/>
                            <span style="font-size:14px">{{ $t('certificate.withscore') }} <b>{{ certificateData.dScore }}</b></span> <br/><br/><br/><br/>
                            <span style="font-size:12px"><i>{{ $t('certificate.date') }}:</i></span><br>     {{ certificateData.dDate }}    
                            <span style="font-size:13px"></span>
                        </div>
                    </div>
                </body>
            </html>
        </section>
    </vue-html2pdf>
   </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
/* eslint-disable */
export default {
    name: 'Certificate',
    props: ['idPersonExam', 'idGroupDetail'],
    components: {
        VueHtml2pdf 
    },
    data() {
        return {
            sStyle: {width:'900px;', height:'790px;', border:'1px solid #E4E3E3', marginLeft:'auto', marginRight:'auto;', padding:'20px;', textAlign:'center',  backgroundImage:'url(' + require( '@/assets/img2/backgrounds/bg_dsbiz.png' ) + ')', backgroundSize:'cover;', fontFamily:'Lato, sans-serif; color:#414141' },
            certificateData: {
                sStudentName: '',
                sExamName: '',
                dScore: 0,
                dDate: '',
                iPassPercent: 0,
                sImagePath: ''
            }
        }
    },
    methods: {
        async GetCertificate() {
            var pIdPersonExam = this.idPersonExam
            var pIdGroupDetail = this.idGroupDetail
                if(pIdPersonExam == 0)
                {
                    pIdPersonExam = null
                }

                if(pIdGroupDetail == 0)
                {
                    pIdGroupDetail = null
                }
            await axios.get(apiURL + 'Student/getCertificate?idPersonExam='+ pIdPersonExam + "&idGroupDetail=" + pIdGroupDetail, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                if(response.data.sErrors.length > 0)
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    router.push('/Student/MyExams');
                    return
                }
                this.certificateData = response.data
                if(this.certificateData.sImagePath != ""){
                    this.sStyle = {width:'900px;', height:'790px;', border:'1px solid #E4E3E3', marginLeft:'auto', marginRight:'auto;', padding:'20px;', textAlign:'center',  backgroundImage:'url(' + require( '@/assets/img2/backgrounds/'+ this.certificateData.sImagePath +'.png' ) + ')', backgroundSize:'cover;', fontFamily:'Lato, sans-serif; color:#414141' }
                }
                this.$refs.html2Pdf.generatePdf()
            } )
            .catch( (error)  => {
                console.log(error)
            } )            

        }
    },
    mounted() {
        this.checkPermisions(this.$router.currentRoute.path)
        this.GetCertificate()
        
    }
        

}
</script>                                                              